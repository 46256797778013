import loadable from "@loadable/component"
import { graphql } from "gatsby"
import React, { ReactElement, ReactNode, useContext } from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { PageContext } from "../../../context/PageContext"
import SubHeading from "../../atoms/SubHeading"
import Wrapper from "../../atoms/Wrapper"
import DatoStructuredTextBlock from "../DatoStructuredTextBlock"
import * as styles from "./DatoForm.module.scss"

const ContactB2B = loadable(() => import("../../organisms/form/ContactB2B"))
const ContactB2C = loadable(() => import("../../organisms/form/ContactB2C"))
const ContactProductSupport = loadable(() => import("../../organisms/form/ContactProductSupport"))
const ContactProductSupportBasin = loadable(() => import("../../organisms/form/ContactProductSupportBasin"))
const CrmForm = loadable(() => import("../../organisms/form/CrmForm"))
const HeaterRegistrationForm = loadable(() => import("../../organisms/form/HeaterRegistrationForm"))
const ShipmentTrackingForm = loadable(() => import("../../organisms/form/ShipmentTrackingForm"))

/**
 * Accepted string values of Form.form field in Dato Block Library
 * https://harvia.admin.datocms.com/admin/blocks_library/803371/fields/3765257/edit
 */
// const DATO_FORMS = [
//   "contact-b2b",
//   "contact-b2c",
//   "contact-product-support",
//   "shipment-tracking",
//   "heater-registration",
// ] as const

// type FormSlug = typeof DATO_FORMS[number]

export type FormCommonProps = {
  title?: ReactNode
  /** Help text or like content from Dato */
  description?: ReactNode
  /** Content (from Dato) shown after successful submission */
  successMessage?: ReactNode
  /** Generic "Something went terribly wrong" message from Dato  */
  errorMessage?: ReactNode
}

export type DatoCrmFormNode =
  | typeof ContactB2C
  | typeof ContactB2B
  | typeof ContactProductSupport
  | typeof ContactProductSupportBasin
  | typeof HeaterRegistrationForm

/**
 * Renders a premapped form component from Dato Content Block named Form.
 * Adds Google ReCaptcha Provider from react-google-recaptcha-v3
 */
const DatoForm = (props: Queries.DatoFormFragment) => {
  // console.debug("Rendering DatoForm", props)

  const { locale } = useContext(PageContext)

  if (!props.form) {
    return null
  }

  const commonProps: FormCommonProps = {
    title: props.subtitle ? <h3>{props.subtitle}</h3> : undefined,
    description: props.description ? <DatoStructuredTextBlock data={props.description} shiftHeadings={2} /> : undefined,
    successMessage: props.successMessage ? (
      <DatoStructuredTextBlock data={props.successMessage} shiftHeadings={2} />
    ) : undefined,
    errorMessage: props.errorMessage ? (
      <DatoStructuredTextBlock data={props.errorMessage} shiftHeadings={2} />
    ) : undefined,
  }

  // for now, form name from Dato is 1:1 to Azure Function endpoint

  const hasRecaptcha = !!props.recaptchaKey

  let formChild: ReactElement
  switch (props.form) {
    case "contact-b2b":
      formChild = <CrmForm endpoint={props.form} form={ContactB2B} recaptcha={hasRecaptcha} {...commonProps} />
      break
    case "contact-b2c":
      formChild = <CrmForm endpoint={props.form} form={ContactB2C} recaptcha={hasRecaptcha} {...commonProps} />
      break
    case "contact-product-support":
      formChild = (
        <ContactProductSupportBasin {...commonProps} />
        //<CrmForm endpoint={props.form} form={ContactProductSupport} recaptcha={hasRecaptcha} {...commonProps} />
      )
      break
    case "shipment-tracking":
      formChild = <ShipmentTrackingForm {...commonProps} />
      break
    case "heater-registration":
      formChild = (
        <CrmForm endpoint={props.form} form={HeaterRegistrationForm} recaptcha={hasRecaptcha} {...commonProps} />
      )
      break
    default:
      console.error(`Unkown From "${props.form}"`)
      return null // don't render anything if form not known
  }

  return (
    <section>
      <SubHeading text={props.title} />
      <div className={styles.content}>
        <div className="layout-container">
          <Wrapper
            condition={hasRecaptcha}
            wrapper={children =>
              props.recaptchaKey ? (
                <GoogleReCaptchaProvider
                  reCaptchaKey={props.recaptchaKey}
                  language={locale || "en"}
                  // useRecaptchaNet="[optional_boolean_value]"
                  useEnterprise={true}
                  scriptProps={{
                    async: true, // optional, default to false,
                    defer: true, // optional, default to false
                    appendTo: "head", // optional, default to "head", can be "head" or "body",
                    nonce: undefined, // optional, default undefined
                  }}>
                  {children}
                </GoogleReCaptchaProvider>
              ) : (
                <>{children}</>
              )
            }>
            {formChild}
          </Wrapper>
        </div>
      </div>
    </section>
  )
}

export default DatoForm

export const query = graphql`
  fragment DatoForm on DatoCmsForm {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    title
    subtitle
    description {
      value
    }
    form
    successMessage {
      value
    }
    errorMessage {
      value
    }
    recaptchaKey
    #locale
  }
`
