export type WrapperProps = {
  /** If true, wrap `children` using `wrapper`, if false render `children` as is */
  condition: boolean
  /** wrappre function component */
  wrapper: (children: JSX.Element) => JSX.Element
  children: JSX.Element
}

/**
 * Wrap `children` conditionally to `wrapper`.
 */
const Wrapper: React.FC<WrapperProps> = ({ children, condition, wrapper }) => (condition ? wrapper(children) : children)

export default Wrapper
